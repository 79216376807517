import { combineReducers, configureStore } from "@reduxjs/toolkit";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";

import productReducer, {
  productFetching,
} from "../features/products/productSlice";
import cartReducer from "../features/products/cartSlice";
import categoryReducer, {
  categoryFetching,
} from "../features/products/categorySlice";
import bestSellingReducer, {
  bestSellingFetching,
} from "../features/products/bestSellingSlice";
import footerDetailsReducer, {
  footerDetails,
} from "../features/products/footerAboutSlice";

import sliderBannerReducer, {
  sliderBannerFatching,
} from "../features/banner/sliderBannerSlice";

import homeBannerReducer, {
  homeBannerFetching,
} from "../features/banner/bannerSlice";

import logoReducer, { logoFatching } from "../features/logo/logoSlice";
import singleProductReducer from "../features/products/singleProductSlice";
import thumbImageReducer from "../features/products/thumbImageSlice";
import shippingChargeReducer from "../features/products/shippingChargeSlice";
import landingPageReducer, {
  landingPageFetching,
} from "../features/products/landingPageSlice";
import singleLandingPageReducer from "../features/products/singleLandingPageSlice";
import authReducer from "../features/auth/authSlice";
import subCategoryReducer, {
  subCategoryFetching,
} from "../features/products/subCategorySlice";
import footerPageSlice, {
  footerPagesFetching,
} from "../features/footerPageSlice";
import singleFooterPageSlice from "../features/singleFooterPageSlice";

const rootPersistConfig = { key: "root", storage, whitelist: ["cart", "auth"] };
const authPersistConfig = { key: "user", storage: storageSession };
const rootReducer = combineReducers({
  products: productReducer,
  singleProduct: singleProductReducer,
  thumbImage: thumbImageReducer,
  cart: cartReducer,
  category: categoryReducer,
  bestSell: bestSellingReducer,
  footerDetails: footerDetailsReducer,
  bannerSlider: sliderBannerReducer,
  homeBanner: homeBannerReducer,
  logo: logoReducer,
  shippingCharge: shippingChargeReducer,
  landingPage: landingPageReducer,
  singleLandingPage: singleLandingPageReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  subCategories: subCategoryReducer,
  footerPages: footerPageSlice,
  singleFooterPage: singleFooterPageSlice,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

store.dispatch(productFetching());
store.dispatch(categoryFetching());

store.dispatch(bestSellingFetching());
store.dispatch(footerDetails());

store.dispatch(sliderBannerFatching());
store.dispatch(homeBannerFetching());
store.dispatch(logoFatching());
store.dispatch(landingPageFetching());
store.dispatch(subCategoryFetching());
store.dispatch(footerPagesFetching());
