import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  A11y,
  Navigation,
  HashNavigation,
  Pagination,
  Scrollbar,
  Grid,
  Autoplay,
  Parallax,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/hash-navigation";
import Card from "../components/Card";

const CategoryWiseProducts = () => {
  const { items: products } = useSelector((state) => state.products);

  const { items: category } = useSelector((state) => state.category);

  const [categoryProduct, setCategoryProduct] = useState([]);

  console.log(categoryProduct);

  console.log(category);

  useEffect(() => {
    setCategoryProduct(
      category?.map((category) => ({
        ...category,
        product: products?.filter(
          (product) => +product.category_id === +category.id
        ),
      }))
    );
  }, [category, products]);

  return (
    <section className="product-section container mx-auto px-3 mb-10">
      {categoryProduct?.map(
        (category) =>
          +category?.featured === 1 && (
            <>
              <div className="header-section  mt-10 mb-5  flex justify-between items-center">
                <h2 className=" title text-3xl ">{category?.name}</h2>

                <Link
                  to={`/categorypage/category/${category?.id}`}
                  className=" underline underline-offset-2 font-semibold md:text-xl text-lg"
                >
                  View All
                </Link>
              </div>
              <Swiper
                modules={[
                  Navigation,
                  Pagination,
                  Scrollbar,
                  A11y,
                  HashNavigation,
                  Grid,
                  Autoplay,
                  Parallax,
                ]}
                spaceBetween={25}
                // slidesPerView={6}
                // navigation
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                hashNavigation
                scrollbar={{ draggable: true }}
                parallax={true}
                grabCursor={true}
                loop={true}
                // breakpoints={{
                //   0: {
                //     slidesPerView: 2,
                //     spaceBetween: 10,
                //   },

                //   425: {
                //     slidesPerView: 3,
                //     spaceBetween: 25,
                //   },
                //   768: {
                //     slidesPerView: 4,
                //     spaceBetween: 25,
                //   },
                //   1024: {
                //     slidesPerView: 5,
                //     spaceBetween: 25,
                //   },
                //   1440: {
                //     slidesPerView: 6,
                //     spaceBetween: 25,
                //   },
                // }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },

                  330: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 25,
                  },

                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 25,
                  },
                  1440: {
                    slidesPerView: 6,
                    spaceBetween: 25,
                  },
                }}
              >
                {category?.product?.slice(0, 8).map((product) => (
                  <SwiperSlide key={product.id}>
                    <Card key={product?.id} product={product} />
                  </SwiperSlide>
                ))}

                {category?.product?.length === 0 && (
                  <p className="text-center col-span-full">No Products Found</p>
                )}
              </Swiper>
            </>
          )
      )}
    </section>
  );
};

export default CategoryWiseProducts;
