import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCall } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { BsCart3 } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
// import { FaRegUserCircle } from "react-icons/fa";

import { useSelector } from "react-redux";
import { FaRegUserCircle } from "react-icons/fa";
import axios from "axios";

const Navbar = ({ handleMenu }) => {
  const { items: data } = useSelector((state) => state.category);
  const { items: subCategory } = useSelector((state) => state.subCategories);
  const { cartItems: product } = useSelector((state) => state.cart);
  const [searchData, setSearchData] = useState([]);
  const [word, setWord] = useState("");
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    const fetchSearchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}/api-products`);
      return setSearchData(res.data);
    };
    fetchSearchData();
  }, []);
  const { items: logo } = useSelector((state) => state.logo);

  const { userAndToken: userData } = useSelector((state) => state.auth);

  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#D89307" : null,
      Font: isActive ? "font-bold" : null,
    };
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchWord = e.target.value;
    setWord(() => searchWord);
    const newFilterData = searchData?.filter((value) =>
      value?.name?.toLowerCase().includes(word?.toLowerCase())
    );

    if (searchWord === "") {
      setFilterData([]);
    } else {
      setFilterData(newFilterData);
    }
  };

  const getSubcategoriesByCategoryId = (categoryId) => {
    return subCategory?.filter(
      (subcategory) => +subcategory.category_id === categoryId
    );
  };
  const handleClose = () => {
    setWord("");
    setFilterData([]);
  };
  return (
    <>
      <div className="bg-[#694704] py-2 px-4 md:px-0 hidden md:block text-slate-50 font-normal text-sm">
        {/* upper nav */}
        <div className="upper-nav container mx-auto  flex items-center justify-between  ">
          <div className="upper-nav-right flex items-center justify-between gap-10">
            <Link>Track Order</Link>
          </div>
          <div className="flex gap-5">
            <div className="flex gap-1 items-center ">
              <MdOutlineCall />
              <p>
                Help line: <a href="tel:09647000112">09647000112</a>
              </p>
            </div>
            <Link>
              Compare<span>(1)</span>
            </Link>
            <Link>
              wishlist<span>(1)</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="sticky px-4 md:px-0 top-0 left-0  right-0 z-[9999] shadow-md bg-white">
        {" "}
        {/* middle nav */}
        <div className="bg-white/75 py-5  ">
          <div className="container mx-auto flex  items-center justify-between">
            <button className="md:hidden block duration-700 text-[#331b08]">
              <FiMenu onClick={handleMenu} className="text-[20px]  " />
            </button>
            <Link to={"/"} className="h-12 md:h-14 ">
              <img
                src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                className="w-full h-full object-cover"
                alt={`${logo?.name}`}
              />
            </Link>
            {/* search button */}
            <div className="hidden md:block w-[30%]">
              <form className="relative flex items-center justify-center w-full">
                <input
                  type="text"
                  placeholder="Search Items......"
                  onChange={handleSearch}
                  value={word}
                  className=" bg-transparent border p-3 px-8  w-[100%]  focus:outline-none "
                />
                <button className="absolute  rounded-full font-extrabold text-black/70 py-2 px-4 top-[10px] right-2">
                  <FiSearch />
                </button>
                {filterData.length !== 0 && (
                  <div className="search-content absolute flex flex-col w-full z-[9999] top-[49px] gap-3 bg-white h-auto  p-2 overflow-hidden overflow-y-scroll">
                    {filterData.slice(0, 5).map((product) => (
                      <Link
                        onClick={handleClose}
                        key={product?.id}
                        to={`/productdetails/${product?.id}`}
                        className="product-card flex items-center    gap-2"
                      >
                        <div className="product-image w-20 h-20 overflow-hidden">
                          <img
                            src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                            alt=""
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="product-content text-black w-full text-sm overflow-hidden">
                          <h4 className="font-semibold">
                            {product?.brand_name}
                          </h4>
                          <p className="truncate text-lg ">{product?.name}</p>
                          {+product.discount > 0 ? (
                            <div className="flex gap-2">
                              <span className="font-semibold text-gray-500 line-through text-accent decoration-200">
                                ৳{product?.unit_price}
                              </span>
                              <span className="text-[#6c350b] font-semibold ">
                                ৳{+product?.after_discount}
                              </span>
                            </div>
                          ) : (
                            <div className="text-gray-400 font-semibold ">
                              ৳{product?.unit_price}
                            </div>
                          )}
                          {/* <span className="text-gray-400">
                            ৳{product?.unit_price}
                          </span> */}
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </form>
            </div>

            <div className=" text-slate-400  text-[1rem] flex justify-center items-center gap-5 duration-1000">
              {!userData && (
                <>
                  <Link
                    to={`/login`}
                    className=" hover:underline  underline-offset-2 duration-300 "
                  >
                    <FaRegUserCircle className="text-2xl text-[#49280c]" />
                  </Link>
                  {/* <span className="text-slate-400/40">or</span>
                  <Link
                    to={`/register`}
                    className="hover:underline underline-offset-2 duration-300"
                  >
                    Registration
                  </Link> */}
                </>
              )}

              {userData && (
                <Link
                  to={`/user`}
                  className="flex items-center  justify-center gap-2"
                >
                  {!userData?.user?.avatar && (
                    <span className=" w-10 h-10 overflow-hidden rounded-full border-2 border-[#D89307]">
                      {/* <FaRegUserCircle className="text-2xl" /> */}
                      <img
                        src="/default_image.jpeg"
                        alt="default avatar"
                        className="w-full h-full object-cover"
                      />
                    </span>
                  )}
                  {userData?.user?.avatar && (
                    <span className=" w-12 h-12  overflow-hidden rounded-full border-2 border-[#D89307]">
                      {/* <FaRegUserCircle className="text-2xl" /> */}
                      <img
                        src={`${process.env.REACT_APP_URL}/uploads/users/${userData?.user?.avatar}`}
                        alt={`${userData?.user?.name}`}
                        className="w-full h-full object-cover"
                      />
                    </span>
                  )}
                  <p className="text-[0.7rem] capitalize md:block hidden  md:text-[1.1rem]">
                    {userData?.user?.name}
                  </p>
                </Link>
              )}
              <Link to="/addtocart" className="hidden md:block  relative group">
                <BsCart3 className="text-2xl text-[#49280c]" />
                <span className=" absolute bottom-3 text-sm left-4 bg-rose-500 h-5 w-5 flex items-center justify-center rounded-full text-white group-hover:bg-sky-500 duration-300">
                  {product.length}
                </span>
              </Link>
              {/* <Link to="/addtocart" className="  relative group">
                <BsCart3 className=" text-[#49280c]" />
                <span className=" absolute bottom-2 text-sm left-2 text-slate-50 bg-rose-500 h-5 w-5 flex items-center justify-center rounded-full group-hover:bg-sky-500 duration-300">
                  {product.length}
                </span>
              </Link> */}
            </div>
          </div>
        </div>
        {/* lower nav */}
        <div className={`bg-[#49280c] hidden md:block`}>
          <div className="container mx-auto">
            <div className="flex justify-center">
              <ul className="flex text-[0.9rem] text-slate-200 uppercase lg:text-[1rem] gap-5 py-2 justify-between items-center">
                <li>
                  <NavLink
                    style={navActive}
                    end
                    to={"/"}
                    className="hover:text-[#D89307] duration-300"
                  >
                    Home
                  </NavLink>
                </li>
                {data?.slice(0, 7).map((navItem) => (
                  <li key={navItem?.id} className="relative group">
                    <NavLink
                      className="relative group"
                      style={navActive}
                      to={`/categorypage/category/${navItem.id}`}
                      key={navItem?.id}
                    >
                      <span className="hover:text-[#D89307] duration-300  ">
                        {navItem?.name}
                      </span>
                      <ul className="absolute hidden group-hover:block group-hover:ease-in-out duration-300 bg-[#49280c] w-48 top-5">
                        {getSubcategoriesByCategoryId(navItem?.id).map(
                          (subCat) => (
                            <li
                              key={subCat.id}
                              className="px-5 py-1 hover:bg-[#50331a] cursor-pointer"
                            >
                              <Link to={`/subcategory/${subCat?.id}`}>
                                {subCat.name}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
